<template>
  <section>
    <b-overlay :show="show" rounded="sm">
      <template #overlay>
        <div class="text-center" style="margin-top: 35em">
          <div class="loading" style="margin-top: 10em">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
          <div>
            <h4 id="cancel-label">กรุณารอสักครู่</h4>
            <!-- <h4 id="cancel-label">กำลังทำรายการ...</h4> -->
          </div>
        </div>
      </template>
      <div class="text-center">
        <h1 class="mt-1"> แพ็กเกจของคุณ</h1>
        <div id="package_data">
        <h5> แพ็จเกจ {{ data_stores.package }}</h5>
        <h5> วันเริ่มต้นใช้งาน {{ data_stores.start_date }}</h5> 
        <h5>วันหมดอายุ {{ data_stores.expiry_date }}</h5>
        </div>

        <!-- <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
          <h6 class="mr-1 mb-0">รายเดือน</h6>
          <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="annually"
            unchecked-value="monthly"
            switch
            @input="tooglePlan"
          />
          <h6 class="ml-50 mb-0">
            รายปี
            <code>ฟรี 2 เดือน</code>
          </h6> -->
        </div>
      </div>

      <div align="center" class="mb-2" v-if="!data_check_credit_card">
        <b-alert variant="warning" show>
          <div class="blue box ex2 alert-body">
            <div class="coral item">
              <span class="pink item"
                ><div style="margin-top: 10px">
                  โปรดลงทะเบียนวิธีชำระเงินเพื่อซื้อแพ็กเกจรายเดือน
                </div></span
              >
            </div>
            <div class="coral item">
              <span class="pink item">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  :to="{ path: '/PaymentMethod' }"
                >
                  ลงทะเบียนวิธีชำระเงิน
                </b-button>
              </span>
            </div>
          </div>
        </b-alert>
      </div>
      <b-row class="pricing-card">
        <b-col class="mx-auto">
          <b-row>
            <b-col md="4">
              <b-card>
                <h1 align="center">Free</h1>
                <p align="center">เหมาะกับธุรกิจเริ่มต้น</p>
                <div align="center" class="annual-plan">
                  <div class="plan-price mt-2">
                    <div v-if="monthlyPlanShow === true">
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{ data["package1"]["price_m"] || 0 }}
                      </span>
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ เดือน
                      </sub>
                    </div>
                    <div v-else>
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{ data["package1"]["price_y"] || 0 }}</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ ปี
                      </sub>
                        <br/>&nbsp&nbsp
                    </div>
                  </div>
                </div>

                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    จำนวน เราเตอร์ <b>(Gateway) 1 อุปกรณ์</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >รองรับสาขา <b>1 สาขา</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >เพิ่มเพื่อน LINE OA อัตโนมัติ (1000)
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-danger">
                      <feather-icon size="25" icon="XCircleIcon" />
                    </b-avatar>
                    &nbsp;ระบบ CRM</b-col
                  >
                </b-row>
                <hr />
                <!-- <b-row>
                  <b-col
                    ><b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    &nbsp;Ad Sponsored</b-col
                  >
                </b-row> -->

                <div v-if="data_check_packages.package_id === data['package1']['id']">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-2"
                    variant="info"
                  >
                    แพ็คเกจที่ใช้ในรอบหน้า
                  </b-button>
                  <div align="center" class="badge-glow">
                    <a
                      style="color: #2bc85e"
                      @click="cancel_packages(data_check_packages)"
                      >ยกเลิก</a
                    >
                  </div>
                </div>
                <div v-else>
                  <div v-if="package_use === data['package1']['name']">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="secondary"
                    >
                      กำลังใช้งาน
                    </b-button>
                  </div>
                  <div v-else @click="goto_checkout(1, data['package1']['name'])">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="success"
                      v-if="package_use"
                    >
                      ดาวน์เกรด
                    </b-button>

                    <b-button
                      v-else
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="success"
                    >
                      อัพเกรด
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-card>
                <h1 align="center">Standard</h1>
                <p align="center">เหมาะกับธุรกิจเขนาดเล็ก</p>
                <div class="annual-plan">
                  <div align="center" class="plan-price mt-2">
                    <div v-if="monthlyPlanShow === true">
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{
                          new Intl.NumberFormat("en-IN").format(
                            data["package2"]["price_m"] || 0
                          )
                        }}</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ เดือน
                      </sub>
                    </div>
                    <div v-else>
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{
                          new Intl.NumberFormat("en-IN").format(
                            data["package2"]["price_y"] || 0
                          )
                        }}</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ ปี
                      </sub>
                      <br>
                     <span style="font-size:10px"> เฉลี่ย 499 บาท / เดือน</span>
                    </div>
                  </div>
                </div>

                <b-row>
                  <b-col
                    ><b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    &nbsp;จำนวน เราเตอร์ <b>(Gateway) 2 อุปกรณ์</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >รองรับสาขา <b>1 สาขา</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >&nbsp;เพิ่มเพื่อน LINE OA อัตโนมัติ
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >&nbsp;ระบบ CRM</b-col
                  >
                </b-row>
                <hr />
                <!-- <b-row>
                  <b-col>
                    <b-avatar variant="light-danger">
                      <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
                    >&nbsp;Ad Sponsored</b-col
                  >
                </b-row> -->

                <!-- ######################################################################## -->
                <div v-if="data_check_packages.package_id === data['package2']['id']">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-2"
                    variant="info"
                  >
                    แพ็คเกจที่ใช้ในรอบหน้า
                  </b-button>
                  <div align="center" class="badge-glow">
                    <a
                      style="color: #2bc85e"
                      @click="cancel_packages(data_check_packages)"
                      >ยกเลิก</a
                    >
                  </div>
                </div>
                <div v-else>
                  <div v-if="package_use === data['package2']['name']">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="secondary"
                    >
                      กำลังใช้งาน
                    </b-button>
                  </div>
                  <div v-else @click="goto_checkout(2, data['package2']['name'])">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="success"
                      v-if="package_use"
                    >
                      ดาวน์เกรด
                    </b-button>

                    <b-button
                      v-else
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="success"
                    >
                      อัพเกรด
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-card>
                <h1 align="center">Pro</h1>
                <p align="center">เหมาะกับธุรกิจรายสาขา</p>
                <div align="center" class="annual-plan">
                  <div class="plan-price mt-2">
                    <div v-if="monthlyPlanShow === true">
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{
                          new Intl.NumberFormat("en-IN").format(
                            data["package3"]["price_m"] || 0
                          )
                        }}</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ เดือน
                      </sub>
                    </div>
                    <div v-else>
                      <span class="pricing-basic-value font-weight-bolder text-primary">
                        {{
                          new Intl.NumberFormat("en-IN").format(
                            data["package3"]["price_y"] || 0
                          )
                        }}</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >บาท/ ปี</sub
                      >
                      <br>
                       <span style="font-size:10px"> เฉลี่ย 1249 บาท / เดือน</span>
                    </div>
                  </div>
                </div>
                <b-row>
                  <b-col
                    ><b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    &nbsp;&nbsp;จำนวน เราเตอร์ <b>(Gateway) 10 อุปกรณ์</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" /> </b-avatar
                    >รองรับสาขา <b> 1-5 สาขา</b>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    &nbsp;&nbsp;เพิ่มเพื่อน LINE OA อัตโนมัติ
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar>
                    &nbsp;&nbsp;ระบบ CRM</b-col
                  >
                </b-row>
                <hr />
                <!-- <b-row>
                  <b-col>
                    <b-avatar variant="light-danger">
                      <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
                    >&nbsp;Ad Sponsored</b-col
                  >
                </b-row> -->
                <!-- {{ data_check_packages.package }} -->
                <!-- ######################################################################## -->
                <div v-if="data_check_packages.package_id === data['package3']['id']">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-2"
                    variant="info"
                  >
                    แพ็คเกจที่ใช้ในรอบหน้า
                  </b-button>
                  <div align="center" class="badge-glow">
                    <a
                      style="color: #2bc85e"
                      @click="cancel_packages(data_check_packages)"
                      >ยกเลิก</a
                    >
                  </div>
                </div>
                <div v-else>
                  <div v-if="package_use === data['package3']['name']">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="secondary"
                    >
                      กำลังใช้งาน
                    </b-button>
                  </div>
                  <div v-else @click="goto_checkout(3, data['package3']['name'])">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      block
                      class="mt-2"
                      variant="success"
                    >
                      อัพเกรด
                    </b-button>
                  </div>
                </div>
                <!-- ######################################################################## -->
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <p style="font-size: 12px; margin-top: -20px">
        *เมื่อซื้อแพ็กเกจรายเดือน (เสียค่าบริการ)
        ในเดือนแรกจะคำนวณค่าบริการที่ต้องชำระจากจำนวนวันที่ใช้บริการจนถึงสิ้นเดือนนั้น
        <br />
        * ในกรณีที่เปลี่ยนแพ็กเกจ คุณจะต้องใช้แพ็กเกจเมไปจนถึงสิ้นเดือน
        และใช้แพ็กเกจใหม่ได้ในเดือนถัดไป
      </p> -->
      <b-row class="pricing-card">
        <b-col class="mx-auto">
          <b-card align="center">
            <b-row>
              <b-col> </b-col>
              <b-col> <b>Free</b> </b-col>
              <b-col> <b>Standard</b> </b-col>
              <b-col> <b>Pro </b> </b-col>
              <!-- <b-col> Advanced Plan </b-col>  --> </b-row
            ><br />
            <div><b>ฟีเจอร์ มาตรฐาน</b></div>
            <!-- <b-row>
              <b-col> </b-col>
              <b-col> เหมาะกับธุรกิจเริ่มต้น </b-col>
              <b-col> เหมาะกับธุรกิจขนาดกลางถึงใหญ่ </b-col>
              <b-col> เหมาะกับธุรกิจขนาดกลางถึงใหญ่ </b-col>
              <b-col> เหมาะกับธุรกิจเขนาดกลางถึงใหญ่ </b-col>  
              </b-row 
              ><br /> -->
            <!-- <b-row>
              <b-col> </b-col>
              <b-col>
                {{
                  monthlyPlanShow == true
                    ? new Intl.NumberFormat("en-IN").format(data["package1"]["price_m"]) +
                      " บาท/เดือน"
                    : new Intl.NumberFormat("en-IN").format(data["package1"]["price_y"]) +
                      " บาท/ปี"
                }}
              </b-col>
              <b-col>
                {{
                  monthlyPlanShow == true
                    ? new Intl.NumberFormat("en-IN").format(data["package2"]["price_m"]) +
                      " บาท/เดือน"
                    : new Intl.NumberFormat("en-IN").format(data["package2"]["price_y"]) +
                      " บาท/ปี"
                }}
              </b-col>
              <b-col>
                {{
                  monthlyPlanShow == true
                    ? new Intl.NumberFormat("en-IN").format(data["package3"]["price_m"]) +
                      " บาท/เดือน"
                    : new Intl.NumberFormat("en-IN").format(data["package3"]["price_y"]) +
                      " บาท/ปี"
                }}
              </b-col>
              <b-col> 5990฿/Mo </b-col>
            </b-row> -->
            <br />
            <b-row>
              <b-col> จำนวน เราเตอร์ (Gateway) </b-col>
              <b-col> 1 อุปกรณ์</b-col>
              <b-col> 2 อุปกรณ์</b-col>
              <b-col> 10 อุปกรณ์</b-col>
              <!-- <b-col>15 </b-col> -->
            </b-row>
            <br />
            <b-row>
              <b-col> รองรับสาขา </b-col>
              <b-col> 1 สาขา</b-col>
              <b-col> 1 สาขา</b-col>
              <b-col> 1-5 สาขา</b-col>
              <!-- <b-col>10 </b-col> -->
            </b-row>
            <br />
            <b-row>
              <b-col> เพิ่มเพื่อน LINE OA อัตโนมัติ </b-col>
              <b-col> 1000 </b-col>
              <b-col> ไม่จำกัด</b-col>
              <b-col>ไม่จำกัด</b-col>
              <!-- <b-col>150,000 </b-col> -->
            </b-row>
            <br />
            <b-row>
              <b-col> ระบบ CRM </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />
            <!-- <b-row> -->
            <!-- <b-col> Marketing Automation </b-col> -->
            <!-- <b-col> จำกัด </b-col> -->
            <!-- <b-col> ไม่จำกัด</b-col> -->
            <!-- <b-col> ไม่จำกัด</b-col> -->
            <!-- </b-row> -->
            <!-- <br />
            <b-row>
              <b-col>Ad Sponsored </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
              ></b-col>
            </b-row> -->
            <br />

            <hr />
            <div><b> WiFi ฟีเจอร์</b></div>
            <br />
            <b-row>
              <b-col> แก้ไขชื่อ SSID </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <!-- <b-col><b-avatar variant="light-success" class="mr-1">
                      <feather-icon size="25" icon="CheckIcon" />
                    </b-avatar> </b-col> -->
            </b-row>
            <br />
            <b-row>
              <b-col> กำหนดค่า WiFi bandwidth </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />

            <b-row>
              <b-col> LINE ล็อกอิน </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />

            <hr />
            <div><b>แคมเปญ</b></div>
            <br />
            <b-row>
              <b-col> สร้างโปรโมชั่น Wi-Fi captive </b-col>
              <b-col> 1 โปรโมชั่น</b-col>
              <b-col> ไม่จำกัด</b-col>
              <b-col> ไม่จำกัด</b-col>
            </b-row>
            <br />
            <b-row>
              <b-col> Broadcast message </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col> Push Message (Wifi Connect) </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <hr />
            <div><b>ข้อมูลเชิงลึก</b></div>
            <br />
            <b-row>
              <b-col> Wifi analytics </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col> Visitor analytics </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <hr />
            <div><b>CRM</b></div>
            <br />
            <b-row>
              <b-col> LINE user </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />

            <hr />
            <div><b>การจัดการข้อมูล</b></div>
            <br />
            <b-row>
              <b-col> การสร้างกลุ่มเป้าหมาย </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col> การสร้าง TAG </b-col>
              <b-col>
                <b-avatar variant="light-danger" class="mr-1">
                  <feather-icon size="25" icon="XCircleIcon" />
                </b-avatar>
              </b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" /> </b-avatar
              ></b-col>
              <b-col>
                <b-avatar variant="light-success" class="mr-1">
                  <feather-icon size="25" icon="CheckIcon" />
                </b-avatar>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>


    <h5>เงื่อนไข</h5> 
      <b-row>
        <b-col>
          • โปรโมชันนี้เป็นไปตามเงื่อนไขที่บริษัทกำหนด
        </b-col>
        <b-col>
          •บริษัทขอสงวนสิทธ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า  
        </b-col>
        <b-col>
          • ราคาเป็นไปตามแพ็คเกจที่คุณเลือก โปรดติดต่อฝ่ายขายเพื่อเลือกแพ็คเกจที่เหมาะสมกับร้านคุณ
        </b-col>
        <b-col>
          • ราคาดังกล่าวคือค่าบริการซอฟต์แวร์ ยังไม่รวมค่าอุปกรณ์ฮาร์ดแวร์
        </b-col>
      </b-row>



      <Checkout ref="Checkout"></Checkout>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BAvatar,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import Checkout from "./Checkout.vue";
/* eslint-disable global-require */
import api from "@/api";
export default {
  components: {
    Checkout,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BAlert,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data_stores: "",
      show: true,
      status: "monthly",
      monthlyPlanShow: false,
      pricing: {},
      price: [],
      package_use: "Free",
      id: null,
      data: [],
      data_check_credit_card: [],
      data_check_packages: [],
    };
  },
  created() {},
  mounted() {
    this.package_price();
    this.check_credit_card();
    this.check_packages();
    this.getstore_id();
  },
  methods: {
    package_price() {
      const params = {
        id: this.$route.query.id,
        type: this.$route.query.status,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("packages", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.data :>> ", response.data);
          this.data = response.data.data;
          this.package_use = response.data.stores.package;
        })
        .catch((error) => {});
    },
    // tooglePlan() {
    //   if (this.status === "monthly") {
    //     this.monthlyPlanShow = true;
    //   } else {
    //     this.monthlyPlanShow = false;
    //   }
    //   console.log("monthlyPlanShowt ", this.monthlyPlanShow);
    // },

    goto_checkout(ids, data) {
      // console.log("data ", data);
      // console.log("this.data_check_packages :>> ", this.package_use);

      console.log("ids :>> ", ids);
      this.id = ids;
      if (this.data_check_credit_card && !this.data_check_packages) {
        this.$refs.Checkout.package_price(this.id, 'year');
      } else {
        //ถ้าฟรี ??
        this.$swal({
          title: "Error!",
          text: " ยังไม่สามารถอัพเกรดได้!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    check_credit_card() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("check_credit_card", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.check_credit_card :>> ", response.data);
          this.data_check_credit_card = response.data;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },

    check_packages() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("check_packages", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.check_packages :>> ", response.data);
          this.data_check_packages = response.data;
          this.show = false;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },

    getstore_id() {
      if (localStorage.getItem("store_id")) {
        const params = {
          store_id: localStorage.getItem("store_id"),
        };
        api
          .post("getstore_id", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("oa response.data getstore_id:>> ", response.data);
            this.data_stores = response.data.data[0];
          })
          .catch((error) => {});
      }
    },


    cancel_packages(data) {
      // console.log("data :>> ", data);
      // console.log("this.package_use :>> ", this.package_use);
      this.$swal({
        title: "คุณต้องการยกเลิก?",
        text: "คุณจะไม่สามารถย้อนกลับได้!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-outline-secondary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            package_use: this.package_use,
            data: data,
            store_id: localStorage.getItem("store_id"),
          };
          console.log("params :>> ", params);
          api
            .post("cancel_packages", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log("response.data :>> ", response.data);
              this.$swal({
                icon: "success",
                title: "สำเร็จ!",
                text: "ยกเลิกรายการสำเร็จ",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.package_price();
              this.check_packages();
              window.location.reload();
            })
            .catch((error) => {
              this.$swal({
                icon: "error",
                title: "Error!",
                text: "รายการผิดพลาด",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
#id_con{
 width:120px;
}
.position-absolute {
  top: 0% !important;
}
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
